<template>
  <router-view :instituteOwn="instituteOwn" :associations="associations" :guruInitialised="guruInitialised" />
</template>

<script>
import formMixin from './../assets/javascript/form-mixin'
import { eventBus } from './../assets/javascript/event-bus'
import { GURU_EVENTS, JD_CONST } from '../assets/javascript/constants'

export default {
  name: 'home',
  mixins: [formMixin],
  data: function () {
    return {
      associations: [],
      instituteOwn: [],
      guruInitialised: false
    }
  },
  mounted () {
    eventBus.$on(JD_CONST.SIGN_IN, () => {
      this.initGuru()
    })

    eventBus.$on(GURU_EVENTS.GURU_INIT, () => {
      this.initGuru()
    })

    this.initGuru()
  },
  beforeDestroy () {
    eventBus.$off(JD_CONST.SIGN_IN)
    eventBus.$off(GURU_EVENTS.GURU_INIT)
  },
  methods: {
    initGuru () {
      this.executeAPIWitoutValiation({
        url: '/api/guru-api/init',
        method: 'post',
        successAction: (data) => {
          if (data.associations) {
            this.associations = data.associations
          }

          if (data.instituteOwn) {
            this.instituteOwn = data.instituteOwn
          }

          this.guruInitialised = true
        },
        failureAction: () => {
          this.guruInitialised = true
        },
        markCompleted: true,
        form: this.doodleForm
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
